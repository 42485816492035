import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {dialogContent, margin0, padding0, padding2, padding3, topActionButtonBaseStyle} from '../components/Styles'
import {ActionTitles} from '../components/Constants'
import CancelIcon from '@mui/icons-material/CancelOutlined'
import {Button} from 'react-admin'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

function CustomDialog(props) {
  const { 
    openDialog, 
    closeDialog, 
    handleSubmit, 
    title, 
    content, 
    dialogPaperProps,
    invalid,
    submitBtnTitle,
    submitBtnIcon,
  } = props;

  const [disabled, setDisabled] =  useState(false);

  return (
    <>
      <Dialog
        disablebackdropclick
        disableEscapeKeyDown
        open={openDialog}
        onClose={closeDialog}
        PaperProps={ dialogPaperProps ? dialogPaperProps : {} }
        fullWidth={true}
      >
        <DialogTitle>{ title }</DialogTitle>
        <DialogContent sx={dialogContent}>
          { content }
        </DialogContent>
        <DialogActions sx={{...padding0.top, ...padding2.bottom, ...padding3.x}}>
          <Button
            variant="outlined"
            size="small"
            sx={{...topActionButtonBaseStyle("gray", "#fff", "#eaeaea", "#b2afaf", "#9B9999")}}
            onClick={closeDialog}
            label={ActionTitles.CANCEL}
          >
            <CancelIcon />
          </Button>
          <Button
            sx={{...topActionButtonBaseStyle(), ...margin0.right}}
            variant="contained"
            size="small"
            onClick={() => {
              setDisabled(true);
              handleSubmit();
              setTimeout(() => setDisabled(false), 500);
            }}
            disabled={ invalid || disabled }
            label={ submitBtnTitle ? submitBtnTitle : "Ok" }
          >
            {submitBtnIcon ? submitBtnIcon : <CheckOutlinedIcon />}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomDialog;