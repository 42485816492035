import * as React from "react";
import { Menu, MenuItemLink, useResourceDefinitions } from "react-admin";
import DefaultIcon from "@mui/icons-material/ViewList";
import { RESOURCES } from "../../Constants";
import { menu } from "../../Styles"
import {Grid} from '@mui/material'

const CustomMenu = () => {
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);
  
  return (
    <Menu sx={menu.container}>
      <Grid sx={menu.logoContainer}>
        <img id="menu-logo" src="whiteLogo.png" alt="Armalytix Logo" style={menu.logo} />
      </Grid>
      {resources.map(
        (resource) =>
          resource.name !== RESOURCES.SURVEYS && (            
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                <div>
                  {(resource.options && resource.options.label) ||
                    resource.name}
                </div>
              }
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              sx={menu.link}
            />
          )
      )}
    </Menu>
  );
};

export default CustomMenu;
