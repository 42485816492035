import {Grid} from '@mui/material'
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import isEmpty from 'lodash/isEmpty'
import {
  filterFieldBasedOnName,
  canFieldBeHidden,  
} from '../../utils/CreateReportUtils';
import {
  TextInput,
  SelectInput,
  Create,
  SimpleForm,
  useNotify,
  Loading,
  useDataProvider,
  required
} from "react-admin";
import { useWatch } from 'react-hook-form';
import React from "react";
import { isSchedulingSupported, httpClient, showTemplateDropdown } from "../DataProvider";

import {
  ActionTitles, RESOURCES, RESOURCES_BASEPATH,
} from "../Constants";
import {
  requiredValidation,
  maxLengthValidation,
  emailWithSpacesValidation
} from "../../utils/util";
import ListActions from "../common/ListActions";
import CustomToolbar from "../common/FormToolbar";
import TextInputWithCharCount from "../common/TextInputWithCharCount";
import SurveyDynamicFields from "./SurveyForCreateReportGrid";
import CurrencyAndAmountDynamicFields from "./CurrencyAndATPForCreateReportGrid";
import ReportTypeAndDynamicTimeRange from "./ReportTypeAndDateRangeForCreateReportGrid";
import SurveyATPDynamicField from "./SurveyATPForCreateReportGrid";
import InsightsDynamicField from "./InsightsForCreateReportGrid";
import IncomeDynamicFields from "./IncomeVerificationForCreateReportGrid";
import { extractOriginalRequestIdFromURL, extractQueryParamsFromURL } from "../../mappers/Report";
import { CreateSecondReportGrid } from "./CreateSecondReportGrid";
import { useLocation } from "react-router";
import { LargeItemDynamicInput } from "./LargeItemDynamicInput";
import {contentCenter,padding0,padding2,simpleForm,textColor,width} from '../Styles'

export const ReportToolbar = (props) => {
  const values = useWatch();

  return (
    <CustomToolbar
      {...props}
      buttontitle1={ActionTitles.CREATE_AND_ADD_ANOTHER}
      buttontitle2={ActionTitles.CREATE_REPORT}
      forReports
      isScheduled={isSchedulingSupported(values.reportType, values.frequency)}
      resource={RESOURCES.REPORTS}
      basePath={RESOURCES_BASEPATH.REPORTS}
    />
  );
};

export const ReportCreate = () => {
  //intially show fields based on default template
  const [loading, setLoading] = React.useState(true);
  const [fields, setFields] = React.useState([]);
  const notify = useNotify();
  const defaultTemplate = localStorage.getItem('defaultTemplate');
  const parsedTemplate = JSON.parse(defaultTemplate);
  const defaultTemplateId = parsedTemplate.templateId;
  const allowedTemplates = JSON.parse(localStorage.getItem('allowedTemplates'));
  const location = useLocation();

  const enableSecondCreateReportPage = extractQueryParamsFromURL(location?.search, 'secondCreateReport');
  const originalClientRequestId = enableSecondCreateReportPage && extractOriginalRequestIdFromURL(location?.pathname);

  // intial template should be any of the templates mapped to client or the default template in case nothing is mapped
  const [selectedTemplateId, setSelectedTemplateId] = React.useState(
    allowedTemplates.length > 0 ? allowedTemplates[0].id : defaultTemplateId
  );

  React.useEffect(() => {
    async function fetchData() {
      const apiUrl = process.env.REACT_APP_API_ENDPOINT;
      const template = `${apiUrl}/template/${selectedTemplateId}`;
      await httpClient(template, { method: 'GET' })
        .then(({ json }) => {
          setFields(json.data.templateData.fields);
          setLoading(false);
        })
        .catch((error) => {
          // incase of an error, show notification and switch to default template fields which were loaded initially while login
          notify('Something went wrong while fetching the template.', { type: 'info', autoHideDuration: 1000 });
          setFields(parsedTemplate.templateData.fields);
          setLoading(false);
        });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateId, notify]);

  const setTemplateValue = (event) => {
    setSelectedTemplateId(event.target.value);
  };

  if (loading) return <Loading />;
  return (
    <Create
      actions={<ListActions title={ActionTitles.CREATE_REPORT} renderButtonAndSearch={false} />}
      sx={contentCenter}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={<ReportToolbar basePath={RESOURCES_BASEPATH.REPORTS} hideButton2={enableSecondCreateReportPage} enableSecondCreateReportPage={enableSecondCreateReportPage} />}
        mode="onBlur"
        reValidateMode="onBlur"
        sx={simpleForm}
      >
        {enableSecondCreateReportPage ? (
          <CreateSecondReportGrid            
            originalClientRequestId={originalClientRequestId}
          />
        ) : (
          <Grid container flexGrow={1}>
            <Grid xs={12}>
              <List sx={padding0.y}>
                {showTemplateDropdown() && (
                  <ListItem sx={{...padding0.top, ...padding2.bottom}}>
                    <Grid container alignItems="center">
                      <Grid xs={0} md={2}></Grid>
                      <Grid xs={12} md={8}>
                        <SelectInput
                          label="Template For Report"
                          fullWidth={true}
                          defaultValue={selectedTemplateId}
                          source="selectedTemplate"
                          name="selectedTemplate"
                          sx={{ ...width.fluid.w100, marginBottom: "-15px" }}
                          onChange={setTemplateValue}
                          choices={JSON.parse(localStorage.getItem("allowedTemplates"))}
                          validate={required()}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                )}
                <CreateElementsForReportGrid
                  selectedTemplateId={selectedTemplateId}
                  fields={fields}
                />
              </List>
              <Divider light />
            </Grid>
          </Grid>
        )}
      </SimpleForm>
    </Create>
  );
};

const CreateElementsForReportGrid = (props) => {

  const [clientSurveys, setClientSurveys] = React.useState([]);
  const [clientSurveysError, setClientSurveysError] = React.useState();  
  const dataProvider = useDataProvider();


  const firstLevelFields = props.fields.filter(field => isEmpty(field.dependentOn)).sort((a, b) => a.position - b.position);
  let components = [];

  const frequencyField = filterFieldBasedOnName("frequency", props.fields)
  const timeRangeField = filterFieldBasedOnName("timeRange", props.fields)
  const currencyField = filterFieldBasedOnName("amountCurrency", props.fields)
  const amountField = filterFieldBasedOnName("amountToProve", props.fields)
  const surveyAmountField = filterFieldBasedOnName("surveyAmountToProve", props.fields)
  const sourceOfFundsSurveyField = filterFieldBasedOnName("sourceOfFundsSurvey", props.fields)
  const factFindSurveyField = filterFieldBasedOnName("factFindSurvey", props.fields)
  const insightsField = filterFieldBasedOnName("insights", props.fields)
  const incomeMandatoryField = filterFieldBasedOnName("incomeMandatory", props.fields)
  const payslipVerificationField = filterFieldBasedOnName("payslipVerification", props.fields)
  const followUpDataRangeField = filterFieldBasedOnName("followUpDataRange", props.fields)
  const largeItemField = filterFieldBasedOnName("largeItem", props.fields)

  React.useEffect(()=>{
    async function getSurveys(){
      dataProvider
      .getList('survey')
      .then(async ({ data }) => {
        setClientSurveys(data);
      })
      .catch((error) => {
        setClientSurveysError(error);
      });
    }
    getSurveys()
  },[dataProvider]);

  firstLevelFields.forEach((field) => {
    if (field.type === 'Textfield') {
      const item = (
        <ListItemComponentForReport
          source={field.name}
          title={field.displayName}
          name={field.name}
          required={field.required}
          label={field.placeholder}
          multiline={field.multiline}
          defaultValue={field.defaultValue}
          hidden={field.isHidden}
          validate={[
            field.required && requiredValidation,
            field.name === 'emailAddress' && emailWithSpacesValidation,
          ]}
        />
      );
      components.push(item);
    } else if (field.type === 'TextfieldWithCharCount') {
      const item = (
        <React.Fragment>
          <Divider light />
          <TextInputWithCharCount
            title={field.displayName}
            source={field.name}
            required={field.required}
            name={field.name}
            label={field.placeholder}
            multiline={true}
            hidden={field.isHidden}
            validate={[field.required && requiredValidation, maxLengthValidation(250)]}
            defaultValue={field.defaultValue}
            maxLength={250}
          />
        </React.Fragment>
      );
      components.push(item);
    } else if (field.type === 'Dropdown' && field.name === 'reportTypes') {
      const item = (
        <React.Fragment>          
          <ReportTypeAndDynamicTimeRange
            reportField={field}
            frequencyField={frequencyField}
            timeRangeField={timeRangeField}
            selectedTemplateId={props.selectedTemplateId}
          />
          <IncomeDynamicFields 
            selectedTemplateId={props.selectedTemplateId}
            payslipVerificationField={payslipVerificationField}
            followUpDataRangeField={followUpDataRangeField}
          />
          <CurrencyAndAmountDynamicFields currencyField={currencyField} amountField={amountField} selectedTemplateId={props.selectedTemplateId} />
          <SurveyDynamicFields
            sourceOfFundsSurveyField={sourceOfFundsSurveyField}
            factFindSurveyField={factFindSurveyField}
            selectedTemplateId={props.selectedTemplateId}
            clientSurveys={clientSurveys}
            clientSurveysError={clientSurveysError}
          />
          <SurveyATPDynamicField surveyAmountField={surveyAmountField} selectedTemplateId={props.selectedTemplateId} />
          <InsightsDynamicField 
            selectedTemplateId={props.selectedTemplateId}
            insightsField={insightsField}
            incomeMandatoryField={incomeMandatoryField} 
          />
          <LargeItemDynamicInput largeItemField={largeItemField} selectedTemplateId={props.selectedTemplateId} />
        </React.Fragment>
      );
      components.push(item);
    }
  });
  return components;
};

const ListItemComponentForReport = (props) => {
  return (
    <React.Fragment>
      <Grid sx={{
        ...canFieldBeHidden(
          props.hidden,
          props.required,
          props.defaultValue
        ) ? { display: "none" } : {} }}>
        <Divider light />
        <ListItem sx={padding2.y}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4} sx={textColor.gray}>
              {props.title}
              {props.required && <span style={{...textColor.red, marginLeft: "3px"}}>*</span>}
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <TextInput
                source={props.source}
                name={props.name}
                label={props.label}
                variant="outlined"
                size="small"
                sx={{...width.fluid.w100, marginBottom: "-15px"}}
                multiline={props.multiline}
                defaultValue={props.defaultValue}
                validate={props.validate}
              />
            </Grid>
          </Grid>
        </ListItem>
      </Grid>
    </React.Fragment>
  );
};