import React from "react";
import {useRecordContext} from 'react-admin'
import { Grid } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'

const tooltipLabelStyle = {
  fontSize: "14px",
  padding: "6px",
  cursor: "pointer"
};

export const TooltipDescription = () => {
  const record = useRecordContext();

  return (
    <Tooltip
      disableInteractive
      placement="right"
      title={
        <Grid sx={tooltipLabelStyle}>{record.description}</Grid>
      }
    >
      <span>{record.name}</span>
    </Tooltip>
  );
};