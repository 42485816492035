import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import DescriptionIcon from "@mui/icons-material/Description";
import { default as SettingsIconMui } from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import CancelIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import TimerOffOutlinedIcon from '@mui/icons-material/TimerOffOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import { textColor, float } from '../Styles'

export const corporatePartnerIcon = () => (
    <SupervisorAccountIcon sx={textColor.white} />
  );


  export const defaultContentIcon = () => (
    <ChromeReaderModeIcon sx={textColor.white} />
  );


  export const reportsIcon = () => (
    <DescriptionIcon sx={textColor.white} />
  );

  export const SettingsIcon = () => (
    <SettingsIconMui sx={textColor.white} />
  );

  export const UsersIcon = () => (
    <PersonIcon sx={textColor.white} />
  );

  export const RiskInsightIcon = () => (
    <AssignmentLateOutlinedIcon sx={textColor.white} />
  );

  export const ClientDefaultsIcon = () => (
    <TuneIcon sx={textColor.white} />
  );

export const StatusMessagesIcon = () => (
    <MonitorHeartIcon sx={textColor.white} />
);

  export const trueCircleIcon = () => (
    <CheckCircleIcon sx={textColor.green} />
  );
  export const falseCircleIcon = () => (
    <CancelIcon sx={textColor.red} />
  );

  export const CalendarIcon = (props) => {
    return <EventOutlinedIcon style={{ color : props.color ? props.color : 'white' }} />
  };

  export const ReminderIcon = (props) => {
    return <TimerOutlinedIcon sx={{...textColor.gray,...float.left}} />
  };

  export const ReminderOffIcon = (props) => {
    return <TimerOffOutlinedIcon sx={textColor.gray} />
  };

  export const AddIconForInputParams = (props) => {
    return <AddCircleOutlineIcon />
  };