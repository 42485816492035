import * as React from "react";
import { Grid } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import {BooleanField, Show, SimpleShowLayout, UrlField, useRecordContext} from 'react-admin'
import { trueCircleIcon, falseCircleIcon } from "../common/StyledIcons";
import ListActions from '../common/ListActions'
import {ActionTitles} from '../Constants'
import {contentCenter, padding0, padding2, textColor, textDecoration} from '../Styles'

export const SettingsShow = () => (
  <Show
    actions={
      <ListActions
        title={ActionTitles.USER_DETAILS}
        showEditButton={true}
      />
    }
    sx={contentCenter}
  >
    <SimpleShowLayout>
      <SettingsShowGrid />
    </SimpleShowLayout>
  </Show>
);

const SettingsShowGrid = () => {
  const record = useRecordContext();
  
  return(
  <Grid container flexGrow={1}>
    <Grid xs={12}>
      <List sx={padding0.y}>
        <ListComponentForSettings title="First Name" value={record["firstName"]} />
        <Divider light />
        <ListComponentForSettings title="Last Name" value={record["lastName"]} />
        <Divider light />
        <ListComponentForSettings title="Email" value={record["email"]} />
        <Divider light />
        <ListComponentForSettings
          title="Email Report Notification"
          value={
            <>
              <BooleanField
                source="emailReportNotifications"
                TrueIcon={trueCircleIcon}
                FalseIcon={falseCircleIcon}
              />
            </>
          }
        />
        <Divider light />
        <ListComponentForSettings title="Secondary Email" value={record["secondaryEmail"]} />
        <Divider light />
        <ListItem sx={padding2.y}>
          <Grid container>
            <Grid xs={12} md={4} sx={textColor.gray}>2FA</Grid>
            <Grid xs={12} md={8}>
              <UrlField
                source="fa"
                label="2FA"
                href={
                  process.env.REACT_APP_KEYCLOAK_LOGIN_URL +
                  "/realms/" +
                  process.env.REACT_APP_KEYCLOAK_REALM +
                  "/account/totp"
                }
                sortable={false}
                sx={textDecoration.none}
              >
                Set up
              </UrlField>
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Grid>
  </Grid>
)};

const ListComponentForSettings = (props) => {
  return (
    <ListItem sx={padding2.y}>
      <Grid container>
        <Grid xs={12} md={4} sx={textColor.gray}>
          {props.title}
        </Grid>
        <Grid xs={12} md={8}>{props.value}</Grid>
      </Grid>
    </ListItem>
  );
};
