import * as React from "react";
import { Grid } from "@mui/material";
import {
  CreateButton,
  EditButton,
  ExportButton,
  SelectColumnsButton,
  ShowButton,  
  useListPaginationContext,
  useNotify,
  useRecordContext,
  useRedirect,
} from 'react-admin'
import {ActionTitles} from '../Constants'
import {pageTitle,topActionButtonBaseStyle,topActionButtonsRow} from '../Styles'
import { isAdminUser } from "../DataProvider";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import CustomDeleteWithConfirmButton from '../../utils/CustomDeleteWithConfirmButton'
import {deleteCallback} from '../../utils/util'
import {useState} from 'react'
import { StatusMessageAlerts } from "./AlertComponent.jsx"

//receives title as a prop for displaying title depending upon page type
//set base path also based on props only
const ListActions = (props) => {
  const notify = useNotify();
  const redirect = useRedirect()
  const record = useRecordContext();
  const paginationContext = useListPaginationContext();
  const isAdmin = isAdminUser();
  const isDisabled = isAdmin && (props.title === ActionTitles.REPORTS || props.title === ActionTitles.SCHEDULES);
  const [disableDelete, setDisableDelete] = useState(false);

  return (
    <Grid container>
      { !isAdmin && <StatusMessageAlerts /> }
      <Grid item container xs={12} justifyContent={'space-between'}>
        <Grid sx={pageTitle}>{props.title}</Grid>
        <Grid sx={topActionButtonsRow}>
          {props.showViewButton === true && (
            <ShowButton
              size="small"
              sx={{...topActionButtonBaseStyle()}}
              icon={<RemoveRedEyeOutlinedIcon />}
              label={ActionTitles.VIEW}
            />
          )}
          {props.showCreateButton === true && (
            <CreateButton
              size="small"
              sx={{...topActionButtonBaseStyle()}}
              icon={<AddCircleOutlineIcon />}
              label={ActionTitles.CREATE}
              to={`${props.basePath}/create`}
              disabled={isDisabled}
            />
          )}
          {props.showEditButton === true && (
            <EditButton
              size="small"
              sx={{...topActionButtonBaseStyle()}}
              icon={<EditOutlinedIcon />}
              label={ActionTitles.UPDATE}
            />
          )}
          {props.showDeleteButton === true && (
            <CustomDeleteWithConfirmButton
              icon={<DeleteOutlinedIcon />}
              sx={{...topActionButtonBaseStyle()}}
              size="small"
              label={ActionTitles.DELETE}
              record={record}
              recordName={props.recordName}
              mutationMode="pessimistic"
              resource={props.resource}
              onSuccess={() => {
                deleteCallback(props.recordName, "success", notify, redirect, props.basePath)
                setDisableDelete(false)
              }}
              onFailure={() => {
                deleteCallback(props.recordName, "failure",notify)
                setDisableDelete(false)
              }}
              disableDelete={disableDelete}
              setDisableDelete={setDisableDelete}
            />
          )}
          {props.ShowExportButton === true && (
            <ExportButton
              size="small"
              maxResults={paginationContext ? paginationContext.total : 1000}              
              sx={{...topActionButtonBaseStyle("#93002c", "#fff", "#fffafa", "#93002c", "#730023")}}
            />
          )}
          {props.showColumnsButton === true &&
            <SelectColumnsButton
              sx={{...topActionButtonBaseStyle("white", "#383848", "#181822")}}
              size="small"
            />
          }
        </Grid>
      </Grid>
      { props.filters && <Grid item container xs={12}>{props.filters}</Grid> }
    </Grid>
  );
}

export default ListActions;
