import React,  { useState } from "react";
import _ from 'lodash';
import Button from "@mui/material/Button";
import CustomDialog from "./CustomDialog";
import CSVSelector from "../components/common/CSVSelector";
import ReportAndFileSelector from "../components/common/ReportAndFileSelector";
import {
  REPORT_FORMATS,
  REPORT_FORMATS_CSV,
  REPORT_FORMATS_CSV_ZIP,
  REPORT_FORMATS_CSV_FILTER,
  REPORT_STATUSES,
  REPORT_TYPES,
  INSIGHTS_REPORT_TYPE,
  EVENT_REASON, ActionTitles,
} from '../components/Constants'
import { httpClient, fileHttpClient, isAdminUser } from "../components/DataProvider";
import { trimFileNameForDownload } from "./util";
import { linkButton } from '../components/Styles'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {Grid} from '@mui/material'

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

function DownloadLink(props) {
  const {
    clientId,
    clientRequestId,
    startDate,
    endDate,
    businessName,
    descriptionOfMatter,
    contactName,
    requestId,
    reportType,
    status,
    onBehalfOfId,
    columns
  } = props;
  const [disableLink, setDisableLink] = useState({
    [REPORT_FORMATS.CSV]: false,
    [REPORT_FORMATS.PDF]: false,
    [REPORT_FORMATS.EXCEL]: false
  });
  const [openCsvSelect, setOpenCsvSelect] = useState(false);
  const [csvType, setCsvType] = useState(REPORT_FORMATS.CSV);
  const [openReportAndFileSelect, setOpenReportAndFileSelect] = useState(false);
  const [reportData, setReportData] = useState({});
  const [riskInsightReportData, setRiskInsightReportData] = useState("");
  const [selectedFiles, setSelectedFiles] = useState({});
  const [supplementaryFiles, setSupplementaryFiles] = useState([]);
  const [reportFilename, setReportFilename] = useState([]);
  const [riskInsightReportFilename, setRiskInsightReportFilename] = useState("");

  const isReportAvailable = (status === REPORT_STATUSES.AVAILABLE);
  const hasSupplementaryFiles = (supplementaryFiles) => (supplementaryFiles && Object.keys(supplementaryFiles).length !== 0);
  const excelEnable =[
    REPORT_TYPES.TRANSACTION_ANALYSIS,
    REPORT_TYPES.DETAILED_AFFORDABILITY,
  ].includes(reportType);
  const csvEnable = [
    REPORT_TYPES.SOURCE_OF_FUNDS_DATA,
    REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS,
    REPORT_TYPES.TRANSACTION_ANALYSIS,
    REPORT_TYPES.TRANSACTION_DOWNLOAD,
    REPORT_TYPES.DETAILED_AFFORDABILITY,
  ].includes(reportType);
  const csvMultiFormatEnable = [REPORT_TYPES.TRANSACTION_DOWNLOAD].includes(
    reportType
  );

  const handleDownload = async (reportFormat) => {
    if (reportFormat) {
      setDisableLink({...disableLink, [reportFormat]: true});
      await getReportData(reportFormat).then(reportData => {
        if(!_.isEmpty(reportData)) {
          //Report Filename
          const toDate = reportData.reportParams && JSON.parse(reportData.reportParams).endDate
            ? JSON.parse(reportData.reportParams).endDate
            : endDate;
          const filename = generateReportFilename(reportFormat, toDate);
          setReportFilename(filename);

          if(reportData.riskInsightReportData !== ""){
            setRiskInsightReportFilename("Risk Insight Report - " + filename);
          }

          if(reportFormat === REPORT_FORMATS.PDF && (hasSupplementaryFiles(reportData.supplementaryFiles) || reportData.riskInsightReportData !== "")) {
            //Supplementary files
            const files = Object.keys(reportData.supplementaryFiles)
                                    .flatMap(accountId => reportData.supplementaryFiles[accountId]);
            setSupplementaryFiles(files);

            handlePDFOpen();
          } else {
            generateReportDownloadLink(reportFormat, reportData.formattedReportData, filename);
          }
        }
      });
      setDisableLink({...disableLink, [reportFormat]: false});
    }
  };

  const generateReportDownloadLink = (reportFormat, base64ReportData, filename) => {
    const isExcel = (reportFormat === REPORT_FORMATS.EXCEL);
    const isCsv = reportFormat in REPORT_FORMATS_CSV;
    const isZip = reportFormat in REPORT_FORMATS_CSV_ZIP;
    let url = `${isCsv ? (isZip ? "data:application/zip;base64" : "data:text/csv;charset=utf-8") :
        (isExcel ? "data:application/xlsx;base64" : "data:application/pdf;base64")
      },${base64ReportData ? encodeURIComponent(base64ReportData) : ""}`;
    generateLink(url, filename);
  }

  const generateFileDownloadLink = (filename, fileType, fileData) => {
    const blob = new Blob([fileData], { type: fileType });
    const url = URL.createObjectURL(blob);
    const prefixedFilename = `${businessName || descriptionOfMatter || contactName}-${filename}`;
    generateLink(url, prefixedFilename);
  }

  const generateLink = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
  }

  const getReportData = async (reportFormat) => {
    const isAdmin = isAdminUser();
    if (reportFormat) {
      let url = isAdmin
        ? `${apiUrl}/admin/report-request/client/${clientId}/report-data/${clientRequestId}?format=${reportFormat}`
        : `${apiUrl}/api/report-request/${clientRequestId}?format=${reportFormat}`;

      let reportData = {};
      await httpClient(url)
      .then(({ json }) => {
        if(json.data && json.data.reportData) reportData = json.data.reportData;;
      });

      let riskInsightUrl = isAdmin
        ? `${apiUrl}/admin/report-request/${clientRequestId}/client/${clientId}/insights-report/${INSIGHTS_REPORT_TYPE.RISK_INSIGHTS}?format=${reportFormat}`
        : `${apiUrl}/api/report-request/${clientRequestId}/insights-report/${INSIGHTS_REPORT_TYPE.RISK_INSIGHTS}?format=${reportFormat}`;

      reportData.riskInsightReportData = "";
      if(reportFormat === REPORT_FORMATS.PDF){
        await httpClient(riskInsightUrl)
          .then(({ json }) => {
          if(json.data && json.data.reportData && json.data.reportData.formattedReportData) {
            reportData.riskInsightReportData = json.data.reportData.formattedReportData;
          }
        });
      }

      setRiskInsightReportData(reportData.riskInsightReportData);
      setReportData(reportData);
      return reportData;
    }
  }

  const generateReportFilename = (reportFormat, reportEndDate) => {
    const isExcel = reportFormat === REPORT_FORMATS.EXCEL;
    const isCsv = reportFormat in REPORT_FORMATS_CSV;
    const isZip = reportFormat in REPORT_FORMATS_CSV_ZIP;

    const opts = { year: "numeric", month: "short", day: "numeric" };
    const fromDate = startDate
      ? new Date(startDate)
          .toLocaleDateString("en-GB", opts)
          .replace(/\s/g, "")
      : "";
    const toDate = reportEndDate
      ? new Date(reportEndDate).toLocaleDateString("en-GB", opts).replace(/\s/g, "")
      : "";

    const fileNamePrefix = `${ businessName || descriptionOfMatter || contactName }`;
    const trimmedFileNamePrefix = trimFileNameForDownload(fileNamePrefix);
    const fileExtension = isCsv ? (isZip ? "zip" : "csv") : (isExcel ? "xlsx" : "pdf");
    return `${trimmedFileNamePrefix}${fromDate ? "-" + fromDate : ""}${toDate ? "-" + toDate : ""}-${requestId}.${fileExtension}`;
  }

  const getFileData = async (fileDownloadUri) => {
    return await fileHttpClient(fileDownloadUri + (isAdminUser() ? `?mode=admin&clientId=${ (onBehalfOfId) ? onBehalfOfId : clientId }` : ''))
      .then(result => result);
  }

  const handleDialogSubmit = (reportFormat) => {
    switch(reportFormat) {
      case REPORT_FORMATS.CSV:
        handleCsvSelect();
        break;
      case REPORT_FORMATS.PDF:
        handleReportAndSupplementaryFileSelect(reportFormat);
        break;
      default:
        break;
    }
  }

  const handleReportAndSupplementaryFileSelect = async (reportFormat) => {
    if(!selectedFiles.hasOwnProperty(reportFormat) || selectedFiles[reportFormat] === true) {
      generateReportDownloadLink(reportFormat, reportData.formattedReportData, reportFilename);
    }
    if(riskInsightReportData !== "" && (!selectedFiles.hasOwnProperty(riskInsightReportFilename) || selectedFiles[riskInsightReportFilename] === true)){
      generateReportDownloadLink(reportFormat, riskInsightReportData, riskInsightReportFilename);
    }
    for(const file of supplementaryFiles) {
      if(!selectedFiles.hasOwnProperty(file.fileName) || selectedFiles[file.fileName] === true) {
        let fileData = await getFileData(file.fileDownloadUri).then(result => result);
        generateFileDownloadLink(file.fileName, file.fileType, fileData);
      }
    }
    setSelectedFiles({}); //Reset selected files
    handlePDFClose();
  };

  const handleCsvSelect = () => {
    handleDownload(csvType);
    handleCSVClose();
  };
  const handleCSVOpen = () => {
    setDisableLink({...disableLink, [REPORT_FORMATS.CSV]: true});
    setOpenCsvSelect(true);
  };
  const handleCSVClose = (event, reason) => {
    if(reason !== EVENT_REASON.BACKDROP_CLICK){
      setOpenCsvSelect(false);
      setDisableLink({...disableLink, [REPORT_FORMATS.CSV]: false});
    }      
  };
  const handlePDFOpen = () => {
    setDisableLink({...disableLink, [REPORT_FORMATS.PDF]: true});
    setOpenReportAndFileSelect(true);
  };
  
  const handlePDFClose = (event, reason) => {
    if(reason !== EVENT_REASON.BACKDROP_CLICK){
      setOpenReportAndFileSelect(false);
      setDisableLink({...disableLink, [REPORT_FORMATS.PDF]: false});
    }
  };

  return (
    <Grid id={clientRequestId} display="flex" flexDirection={columns ? 'column' : 'row'}>
      {isReportAvailable && csvEnable && (
        <Grid>
          <Button
            sx={linkButton}
            color="primary"
            onClick={() =>
              csvMultiFormatEnable
                ? handleCSVOpen()
                : handleDownload(REPORT_FORMATS.CSV)
            }
            disabled={disableLink[csvType]}
          >
            CSV
          </Button>
          <CustomDialog
            title = "Select CSV Format"
            content = {
              <CSVSelector
                choices = {REPORT_FORMATS_CSV_FILTER}
                defaultValue = {REPORT_FORMATS.CSV}
                onChange = {(event) => setCsvType(event.target.value)}
              />
            }
            openDialog = {openCsvSelect}
            closeDialog = {handleCSVClose}
            dialogPaperProps = {{
              style: {
                maxWidth : "224px"
              },
            }}
            handleSubmit = {() => handleDialogSubmit(REPORT_FORMATS.CSV)}
          />
        </Grid>
      )}
      {isReportAvailable ? (
        <Grid>
          <Button
            sx={linkButton}
            color="primary"
            onClick={() => handleDownload(REPORT_FORMATS.PDF)}
            disabled={disableLink[REPORT_FORMATS.PDF]}
          >
            PDF
          </Button>
          <CustomDialog
            title = "Download Report PDF and Supplementary Files"
            submitBtnTitle={ActionTitles.DOWNLOAD}
            submitBtnIcon={<FileDownloadOutlinedIcon />}
            content = {
              <ReportAndFileSelector
                reportFormat = {REPORT_FORMATS.PDF}
                reportFilename = {reportFilename}
                riskInsightReportFilename = {riskInsightReportFilename}
                supplementaryFiles = {supplementaryFiles}
                onReportChange = {(event) => setSelectedFiles({...selectedFiles, [REPORT_FORMATS.PDF]: event.target.checked})}
                onRiskInsightReportChange = {(event) => setSelectedFiles({...selectedFiles, [riskInsightReportFilename]: event.target.checked})}
                onFileChange = {(event, fileName) => setSelectedFiles({...selectedFiles, [fileName]: event.target.checked})}
              />
            }
            openDialog = {openReportAndFileSelect}
            closeDialog = {handlePDFClose}
            handleSubmit = {() => handleDialogSubmit(REPORT_FORMATS.PDF)}
          />
        </Grid>
      ) : (
        "--"
      )}
      {isReportAvailable && excelEnable && (
        <Grid>
          <Button
            sx={linkButton}
            color="primary"
            onClick={() => handleDownload(REPORT_FORMATS.EXCEL)}
            disabled={disableLink[REPORT_FORMATS.EXCEL]}
          >
            EXCEL
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
export default DownloadLink;
