import { useState, useEffect } from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { trueCircleIcon, falseCircleIcon, CalendarIcon } from "../common/StyledIcons";
import {
  List,
  TextField,
  BooleanField,
  ShowButton,
  FunctionField,
  useDataProvider,
  Loading,
  Error,
  useNotify,
  useRefresh,
  downloadCSV,
  DatagridConfigurable,
  useRedirect,useListContext,CreateButton,
} from 'react-admin'
import { ActionTitles, RESOURCES, RESOURCES_BASEPATH } from "../Constants";
import ListActions from "../common/ListActions";
import CustomDeleteWithConfirmButton from "../../utils/CustomDeleteWithConfirmButton";
import CustomTooltip from "../../utils/CustomTooltip";
import HideButton from "../common/HideButton";
import PreviewLink from "../../utils/PreviewLink";
import DownloadLink from "../../utils/DownloadLink";
import DisableReminder from "../common/DisableReminder";
import { REPORT_TYPES_DISPLAY_NAMES } from "../Constants";
import React from "react";
import { isAdminUser } from "../DataProvider";
import { parseEncodedURLforClientId, parseRecordsforExport } from "../../mappers/Report";
import isEmpty from 'lodash/isEmpty'
import isUndefined from "lodash/isUndefined";
import ReportStatusLabel from "./ReportTooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import jsonExport from 'jsonexport/dist';
import { RESOURCES_META_DATA } from "../Constants";
import { CustomPagination } from "../common/CustomPaginationComponent";
import {CustomDataGridHeader, onToggleDataGridHeader} from '../common/CustomDataGridHeader'
import { useLocation } from 'react-router-dom';
import {Box,Grid,IconButton,Typography} from '@mui/material'
import { ExpandReportButton } from "./IncomeVerificationForCreateReportGrid";
import {
  float,
  cellContentCentered,
  dataGrid,
  iconAction,
  listRoot,
  margin1,
  textColor,
  cursor,
  width,
  topActionButtonBaseStyle,ellipsis
} from '../Styles'
import defaultsDeep from 'lodash/defaultsDeep';
import cloneDeep from 'lodash/cloneDeep';
import {ReportsAdminFilter,ReportsFilter} from './ReportsFilters'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Tooltip from '@mui/material/Tooltip'

export const Reports = (props) => {
  const isAdmin = isAdminUser();
  const notify = useNotify();
  const refresh = useRefresh();
  const location = useLocation();
  const redirect = useRedirect();

  const dataProvider = useDataProvider();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [clientId, setClientId] = useState(undefined);
  const [disableDelete, setDisableDelete] = useState(false);
  let dataGridsLS = JSON.parse(localStorage.getItem("dataGrids"))
  if (!dataGridsLS)
    dataGridsLS = { "reports": true }
  const [dataGrids, setDataGrids] = useState(dataGridsLS ? dataGridsLS : {});

  useEffect(() => {
    if(!isAdmin){
        dataProvider
        .getMappedUsersSummary(`client/mapped-users`)
        .then(({ data }) => {
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider]);

  useEffect(() => {
    if(!isEmpty(location.search)){
      setClientId(parseEncodedURLforClientId(location.search));
    }
  }, [location.search]);

  useEffect(()=>{
    if(isAdmin && !isUndefined(clientId)){
      dataProvider
      .getMappedUsersbyClientId({clientId : clientId})
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }
  }, [clientId, dataProvider, isAdmin])
  
    if (loading && !isAdmin) return <Loading />;
    if (error) return <Error />;

  const onDeleteSuccess = () => {
    setDisableDelete(false);
    notify("Report Deleted", { type: 'info', autoHideDuration: 1000 });
    refresh();
  };

  const onDeleteFailure = () => {
    setDisableDelete(false);
    notify("Unable to delete a report", { type: 'warning', autoHideDuration: 1000 });
    refresh();
  };    

  const exporter = (records) => {    
    const list = parseRecordsforExport(records, data);
    
    return jsonExport(list, (err, csv) => {
        downloadCSV(csv, RESOURCES_META_DATA[RESOURCES.REPORTS].displayName);        
    });    
  };

  return (
    <List
      sx={listRoot}
      exporter={exporter}
      actions={
        <ListActions
          title={ActionTitles.REPORTS}
          basePath={RESOURCES_BASEPATH.REPORTS}
          renderButtonAndSearch={true}
          showCreateButton={true}
          ShowExportButton={true}
          showColumnsButton={true}
          filters={isAdmin ? <ReportsAdminFilter requestingUserNames={data} /> : <ReportsFilter requestingUserNames={data} />}
        />
      }
      sort={{ field: 'lastModified', order: 'DESC' }}
      pagination={<CustomPagination />}
      empty={false}
    >
      <DatagridConfigurable
        header={
          <CustomDataGridHeader
            fixedColumn={dataGrids['reports']}
            onToggle={() => onToggleDataGridHeader('reports', dataGrids, setDataGrids)}
          />
        }
        sx={
          defaultsDeep(
            cloneDeep(dataGrid.base),
            cloneDeep(dataGrid.last3ColsActions),
            dataGrids['reports'] ? cloneDeep(dataGrid.firstColSticky) : {}
          )
        }
        bulkActionButtons={false}
      >
        <TextField source="contactName" label="CONTACT NAME" sortable={false} />
        <TextField sx={textColor.primary} source="emailAddress" label="EMAIL ADDRESS" sortable={false} />
        <FunctionField
          sx={cellContentCentered}
          label="PREVIEW"
          source="status"
          title="preview"
          sortable={false}
          render={(record) => (
            <PreviewLink
              clientRequestId={record['clientRequestId']}
              clientId={record['clientId']}
              status={record['status']}
            />
          )}
        />
        <FunctionField
          sx={cellContentCentered}
          source="status"
          label="DOWNLOAD"
          sortable={false}
          render={(record) => <DownloadLink columns={true} {...record} />}
        />
        { !isAdmin &&
        <FunctionField
          sx={cellContentCentered}
          source="Expand"
          label="EXPAND"
          sortable={false}
          render={ (record) => <ExpandReportButton record={record} basePath={RESOURCES_BASEPATH.REPORTS} /> }
        />}
        <TextField source="businessName" label="BUSINESS NAME" sortable={false} />
        <FunctionField
          source="descriptionOfMatter"
          label="DESCRIPTION OF MATTER"
          sortable={false}
          render={(record) => {
            return (
              <Grid item sx={{...ellipsis, maxWidth: "250px"}}>
                <Tooltip title={record.descriptionOfMatter} placement="top">
                  {record.descriptionOfMatter}
                </Tooltip>
              </Grid>
            );
          }}
        />
        <TextField source="clientReference" label="CLIENT REFERENCE" sortable={false} />
        <FunctionField
          label="REPORT TYPE"
          render={(record) => `${REPORT_TYPES_DISPLAY_NAMES[record.reportType]}`}
          sortable={false}
        />
        <TextField
          source="status"
          sortable={false}
          label={
            <Grid container flexWrap="nowrap">
              <Grid sx={margin1.right}>STATUS</Grid>
              <ReportStatusLabel title={<InfoOutlinedIcon sx={{...float.left, ...cursor.pointer}} fontSize="small"/>} />
            </Grid>}
        />
        <BooleanField
          source="automatedEmailSent"
          label="EMAIL"
          sx={cellContentCentered}
          TrueIcon={trueCircleIcon}
          FalseIcon={falseCircleIcon}
          sortable={false}
        />
        <FunctionField
          source="scheduledReminders"
          label="REMINDERS"
          sx={cellContentCentered}
          sortable={false}
          render={(record) => (
            <DisableReminder
              clientRequestId={record['clientRequestId']}
              scheduledReminders={record['scheduledReminders']}
              emailAddress={record['emailAddress']}
            />
          )}
        />
        <FunctionField
          sx={cellContentCentered}
          source="Schedule"
          label="SCHEDULE"
          sortable={false}
          render={(record) =>
            record['reportRequestScheduleId'] && (
              <CustomTooltip title="Schedule" placement="top">
                <IconButton color="secondary" onClick={() => redirect(RESOURCES_BASEPATH.SCHEDULES + "/" + record['reportRequestScheduleId'] + "/show")}>
                  <CalendarIcon color="gray" />
                </IconButton>
              </CustomTooltip>
            )
          }
        />
        <TextField source="created" label="CREATED" sortable={true} />
        <TextField source="lastModified" label="LAST MODIFIED" sortable={true} />
        <BooleanField
          source="insights"
          label="INCOME INSIGHTS"
          sx={cellContentCentered}
          TrueIcon={trueCircleIcon}
          FalseIcon={falseCircleIcon}
          sortable={false}
        />
        <FunctionField
          render={(record) =>
            !isUndefined(data) && data.get(record.requestingUserId) != null ? data.get(record.requestingUserId) : ' '
          }
          label="REQUESTING USER NAME"
          sortable={false}
        />
        <FunctionField
          sx={cellContentCentered}
          source="View"
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip title="View" placement="top">
              <ShowButton
                icon={<RemoveRedEyeOutlinedIcon />}
                sx={iconAction}
                size="medium"
                label={""}
                basePath={RESOURCES_BASEPATH.REPORTS}
                record={record}
              ></ShowButton>
            </CustomTooltip>
          )}
        />
        <FunctionField
          sx={cellContentCentered}
          source="Hide"
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip title={record['hidden'] ? 'Unhide' : 'Hide'} placement="top">
              <HideButton
                hide={!record['hidden']}
                label={""}
                record={record}
                icon={<VisibilityOffOutlinedIcon />}
                resourceName="Report"
                disabled={isAdmin}
                sx={iconAction}
                size="medium"
              ></HideButton>
            </CustomTooltip>
          )}
        />
        <FunctionField
          sx={cellContentCentered}
          source="Delete"
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip title="Delete" placement="top">
              <CustomDeleteWithConfirmButton
                icon={<DeleteOutlinedIcon />}
                sx={iconAction}
                size="medium"
                label={""}
                disabled={isAdmin}
                onSuccess={onDeleteSuccess}
                record={record}
                recordName="Report Request"
                mutationMode="pessimistic"
                resource={RESOURCES.REPORTS}
                onFailure={onDeleteFailure}
                disableDelete={disableDelete}
                setDisableDelete={setDisableDelete}
              />
            </CustomTooltip>
          )}
        />
      </DatagridConfigurable>
    </List>
  );
};

const ReportsEmpty = () => {
  const { basePath } = useListContext();

  return (
    <Box textAlign="center" sx={{ ...width.fluid.w100, paddingTop: "50px" }}>
      <Typography variant="h5" paragraph>
        No Reports present for Client
      </Typography>
      <CreateButton
        sx={{...topActionButtonBaseStyle()}}
        basePath={basePath}
        icon={<AddCircleOutlineIcon />}
        size="small"
        label={ActionTitles.CREATE_REPORT}
      />
    </Box>
  );
};