import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import { Grid } from "@mui/material";
import { canFieldBeHidden } from "../../utils/CreateReportUtils";
import React from "react";
import {padding2,textColor} from '../Styles'

export const CommonInputForCreateReportGrid = (props) => {
  
  const { field } = props;

  return(
    <Grid
      sx={ field && canFieldBeHidden(field?.isHidden, field?.required, field?.defaultValue) ? { display: "none" } : {}}
    >
      <Divider light />
      <ListItem sx={padding2.y}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4} sx={textColor.gray}>
            {field?.displayName ? field.displayName : props.defaultDisplayName }
            {field?.required && <span style={{...textColor.red, marginLeft: '3px'}}>*</span>}
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            {props.input}
          </Grid>
        </Grid>
      </ListItem>
    </Grid>
  );
}