import {
  getParsedDate,
} from '../utils/util';
import isEmpty from 'lodash/isEmpty'

export const parseSettingsForView = (data) => {
  data.id = data.userId;
  data.emailReportNotifications =
    data.userNotificationDto != null ? data.userNotificationDto.emailReportNotifications : false;
  data.secondaryEmail =
    data.userNotificationDto != null && data.userNotificationDto.secondaryEmail != null
      ? data.userNotificationDto.secondaryEmail
      : '';  
  data.created = getParsedDate(data.created);
  data.lastModified = getParsedDate(data.lastModified);
  return data;
};

export const parseSettingsForUpdate = (data) => {
  data.fullName = data.firstName + ' ' + data.lastName;
  data.email = data.email ? data.email.toLowerCase().trim() : data.email;
  data.username = data.email;
  data.userNotificationDto = {
    emailReportNotifications: data.emailReportNotifications,
    secondaryEmail: data.emailReportNotifications
      ? isEmpty(data.secondaryEmail) ? null : data.secondaryEmail.toLowerCase().trim()
      : null,
  };
  delete data['fa'];
  delete data['id'];
  delete data['emailReportNotifications'];
  delete data['secondaryEmail'];
  delete data['created'];
  delete data['lastModified'];
  return JSON.stringify(data);
};

export const parseSettingsForPasswordUpdate = (data) => {
  data.email = data.email ? data.email.toLowerCase() : data.email;
  data.username = data.email;
  delete data['firstName'];
  delete data['lastName'];
  delete data['fullName'];
  return JSON.stringify(data);
};

export const parseSettingsForList = (data) => {
  data.id = data.userId;
  data.fa = "Manage";
  data.emailReportNotifications =
    data.userNotificationDto != null ? data.userNotificationDto.emailReportNotifications : false;
  data.secondaryEmail =
    data.userNotificationDto != null && data.userNotificationDto.secondaryEmail != null
      ? data.userNotificationDto.secondaryEmail
      : '';

  delete data['userId'];
  delete data['username'];
  delete data['fullName'];
  delete data['telephone'];
  delete data['acceptedTermsVersion'];
  delete data['acceptedPrivacyPolicyVersion'];
  delete data['userNotificationDto'];
  return data;
}