import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import { SelectInput, useDataProvider, useNotify } from "react-admin";
import { requiredValidation, secondReportLengthValidation } from "../../utils/util";
import { FOLLOW_UP_DATE_RANGE_CHOICES, INCOME_REPORT_TYPE_CHOICES, RESOURCES } from "../Constants";
import { useFormContext } from 'react-hook-form';
import {padding0,padding2,textColor,width} from '../Styles'

export const CreateSecondReportGrid = (props) => {
  const form = useFormContext();
  const dataprovider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {            
    dataprovider
      .getOne(RESOURCES.REPORTS, { id : props.originalClientRequestId })
      .then(({ data }) => {        
        form.setValue("originalClientRequestId", data.id);
        form.setValue("contactName", data.contactName);
        form.setValue("emailAddress", data.emailAddress);
        form.setValue("userStartDate", data.userStartDateParsed);
        form.setValue("clientReference", data.clientReference);
        form.setValue("descriptionOfMatter", data.descriptionOfMatter);
        form.setValue("businessName", data.businessName);       
      })
      .catch(() => {
        notify('Something went wrong while fetching request', "error", { autoHideDuration: 1000 });
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.originalClientRequestId]);

  return (
    <Grid container flexGrow={1}>
      <Grid xs={12}>
        <List sx={padding0.y}>
          <ListItem sx={padding2.y}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>Report Type</Grid>
              <Grid item xs={12} md={6} lg={5}>
                <SelectInput
                  source="reportType"
                  name="reportType"
                  variant="outlined"
                  size="small"
                  label={"Choose an option"}
                  choices={INCOME_REPORT_TYPE_CHOICES}
                  sx={{...width.fluid.w100, marginBottom: "-15px" }}
                  validate={requiredValidation}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>Report Length</Grid>
              <Grid item xs={12} md={6} lg={5}>
                <SelectInput
                  source="reportLength"
                  name="reportLength"
                  variant="outlined"
                  size="small"
                  label="Up to 1 year can be selected, data range as an option"
                  choices={FOLLOW_UP_DATE_RANGE_CHOICES}
                  sx={{...width.fluid.w100, marginBottom: "-15px" }}
                  validate = {secondReportLengthValidation}
                  isRequired
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
        </List>
      </Grid>
    </Grid>
  );
};
