import {
  ENABLED_FEATURES,
  IMAGE_COMPRESSION,
  INSIGHTS_PERMISSION_TYPE,
  REPORT_BRANDING_TYPES,
  SURVEY_SUPPORT_TYPES,
  SURVEY_SUPPORT_TYPES_DISPLAY_NAMES,
} from '../components/Constants';
import { calculateDaysFromDuration, compressImage, generateDurationStringFromDays } from '../utils/util';

var base64data = null;
//convert file to base64

export async function convertFileToBase64(file) {
  //if updating client and image was not changed then it would already be having base64 and we dont need to convert that
  if (file && typeof file != 'string') {
    let compressedImage;
    if(file.rawFile.size > IMAGE_COMPRESSION.LOWER_COMPRESSION_LIMIT){
      compressedImage = await compressImage(file.rawFile, IMAGE_COMPRESSION.QUALITY, IMAGE_COMPRESSION.CONVERT_SIZE);
      if(compressedImage.size > IMAGE_COMPRESSION.UPPER_COMPRESSION_LIMIT){
        const error = {                    
          message : IMAGE_COMPRESSION.COMPRESSION_ERROR_MESSAGE,
        }          
        throw error;
      }
    }else{
      compressedImage = file.rawFile;
    }
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(compressedImage);
      reader.onloadend = function () {
        base64data = reader.result;
        resolve();
      };
    });
  } else {
    base64data = file;
    return new Promise((resolve, reject) => {
      resolve();
    });
  }
}

//parse data for create
export function parseDataForCreate(data) {
  const enabledFeatures = [];
  let insightsPermissionList = [];

  data['incomeInsightsPermission'] && insightsPermissionList.push(INSIGHTS_PERMISSION_TYPE.INCOME_INSIGHTS);
  data['riskInsightsPermission'] && insightsPermissionList.push(INSIGHTS_PERMISSION_TYPE.RISK_INSIGHTS);

  ENABLED_FEATURES.forEach((entry) => (data[entry.name] && enabledFeatures.push(entry.name)));

  data.clientPreference = {
    primaryColour: data['primaryColour'],
    secondaryColour: data['secondaryColour'],
    tertiaryColour: data['tertiaryColour'],
    reportBranding: data['reportBranding'] ? data['reportBranding'] : REPORT_BRANDING_TYPES.DEFAULT,
    reportScheduling: data['reportScheduling'] ? data['reportScheduling'] : false,
    surveySupportType: data['surveySupportType'] ? data['surveySupportType'] : SURVEY_SUPPORT_TYPES.NONE,
    insightsPermission: insightsPermissionList,
  };
  data.clientPreference.permissionConfiguration = { 
    enabledFeatures,
    customReminderIntervals : parseDaysToDuration(data.customReminderIntervals),
    finalReminderNotification : data['finalReminderNotification'],
  };

  data.icon = base64data != null ? base64data.substring(base64data.indexOf(',') + 1) : null;
  data.email = data.email ? data.email.toLowerCase().trim() : data.email;
  delete data['primaryColour'];
  delete data['secondaryColour'];
  delete data['tertiaryColour'];
  delete data['reportBranding'];
  delete data['reportScheduling'];
  delete data['surveySupportType'];
  delete data['incomeInsightsPermission'];
  delete data['riskInsightsPermission'];
  delete data['customReminderIntervals'];
  delete data['finalReminderNotification'];
  ENABLED_FEATURES.forEach((entry) => (delete data[entry.name]));

  return JSON.stringify(data);
}

export const parseCorporatePartnerData = (data) => {
  const parsedRequest = {
    id: data.clientId,
    name: data.clientName,
    primaryUser: data.email !== null ? data.email : '--',
    primaryColour: data.clientPreference != null ? data.clientPreference.primaryColour : '#000000',
    secondaryColour: data.clientPreference != null ? data.clientPreference.secondaryColour : '#000000',
    tertiaryColour: data.clientPreference != null ? data.clientPreference.tertiaryColour : '#000000',
    reportBranding:
      data.clientPreference && data.clientPreference.reportBranding ? data.clientPreference.reportBranding : REPORT_BRANDING_TYPES.DEFAULT,
    reportScheduling:
      data.clientPreference && data.clientPreference.reportScheduling ? data.clientPreference.reportScheduling : false,
    surveySupportType:
      data.clientPreference != null
        ? SURVEY_SUPPORT_TYPES_DISPLAY_NAMES[data.clientPreference.surveySupportType]
        : SURVEY_SUPPORT_TYPES_DISPLAY_NAMES[SURVEY_SUPPORT_TYPES.NONE],
    incomeInsightsPermission :
      data?.clientPreference?.insightsPermission
        ? data.clientPreference.insightsPermission.includes(INSIGHTS_PERMISSION_TYPE.INCOME_INSIGHTS)
        : false,
    riskInsightsPermission :
      data?.clientPreference?.insightsPermission
        ? data.clientPreference.insightsPermission.includes(INSIGHTS_PERMISSION_TYPE.RISK_INSIGHTS)
        : false,
    logo: data.icon != null ? data.icon : '',
    landingScreenContent: data.landingScreenContent,
    finalReminderNotification : data?.clientPreference?.permissionConfiguration?.finalReminderNotification,
    clientStatus: data.clientStatus,
    customReminderIntervals : parseDurationToDays(data?.clientPreference?.permissionConfiguration?.customReminderIntervals),
  };
  const enabledFeatures = data?.clientPreference?.permissionConfiguration?.enabledFeatures
    ? data.clientPreference.permissionConfiguration.enabledFeatures
    : [];
  ENABLED_FEATURES.forEach((entry) => {
    parsedRequest[entry.name] = enabledFeatures.includes(entry.name);
  });

  return parsedRequest;
};

export const parseCorporatePartnerDataForView = (data) => {
  const parsedRequest = {
    id: data.clientId,
    clientName: data.clientName,
    email: data.email !== null ? data.email : '--',
    replyToEmail: data.replyToEmail !== null ? data.replyToEmail : '',
    notes: data.notes != null ? data.notes : '--',
    primaryColour: data.clientPreference != null ? data.clientPreference.primaryColour : '#000000',
    secondaryColour: data.clientPreference != null ? data.clientPreference.secondaryColour : '#000000',
    tertiaryColour: data.clientPreference != null ? data.clientPreference.tertiaryColour : '#000000',
    reportBranding: data.clientPreference != null ? data.clientPreference.reportBranding : REPORT_BRANDING_TYPES.DEFAULT,
    reportScheduling: data.clientPreference != null ? data.clientPreference.reportScheduling : false,
    surveySupportType:
      data.clientPreference != null ? data.clientPreference.surveySupportType : SURVEY_SUPPORT_TYPES.NONE,
    incomeInsightsPermission :
      data?.clientPreference?.insightsPermission
        ? data.clientPreference.insightsPermission.includes(INSIGHTS_PERMISSION_TYPE.INCOME_INSIGHTS)
        : false,
    riskInsightsPermission :
      data?.clientPreference?.insightsPermission
        ? data.clientPreference.insightsPermission.includes(INSIGHTS_PERMISSION_TYPE.RISK_INSIGHTS)
        : false,
    segment: data.segment,
    icon: data.icon != null ? data.icon : '',
    landingScreenContent: data.landingScreenContent,
    logoUrl: data.logoUrl,
    customReminderIntervals : parseDurationToDays(data?.clientPreference?.permissionConfiguration?.customReminderIntervals),
    finalReminderNotification : data?.clientPreference?.permissionConfiguration?.finalReminderNotification,
    clientStatus: data.clientStatus,
  };

  const enabledFeatures = data?.clientPreference?.permissionConfiguration?.enabledFeatures
    ? data.clientPreference.permissionConfiguration.enabledFeatures
    : [];
  ENABLED_FEATURES.forEach((entry) => {
    parsedRequest[entry.name] = enabledFeatures.includes(entry.name);
  });
  return parsedRequest;
};

export const parseForUpdate = (data) => {
  const enabledFeatures = [];
  let insightsPermissionList = [];

  data['incomeInsightsPermission'] && insightsPermissionList.push(INSIGHTS_PERMISSION_TYPE.INCOME_INSIGHTS);
  data['riskInsightsPermission'] && insightsPermissionList.push(INSIGHTS_PERMISSION_TYPE.RISK_INSIGHTS);

  ENABLED_FEATURES.forEach((entry) => (data[entry.name] && enabledFeatures.push(entry.name)));

  data.clientPreference = {
    primaryColour: data['primaryColour'],
    secondaryColour: data['secondaryColour'],
    tertiaryColour: data['tertiaryColour'],
    reportBranding: data['reportBranding'],
    reportScheduling: data['reportScheduling'],
    surveySupportType: data['surveySupportType'],
    insightsPermission: insightsPermissionList
  };

  data.clientPreference.permissionConfiguration = {
    enabledFeatures,
    customReminderIntervals : parseDaysToDuration(data.customReminderIntervals),
    finalReminderNotification : data['finalReminderNotification'],
  }

  data.icon = base64data ? base64data.substring(base64data.indexOf(',') + 1) : null;
  data.clientId = data.id;
  data.email = data.email ? data.email.toLowerCase().trim() : data.email;
  delete data['primaryColour'];
  delete data['secondaryColour'];
  delete data['tertiaryColour'];
  delete data['reportBranding'];
  delete data['reportScheduling'];
  delete data['surveySupportType'];
  delete data['incomeInsightsPermission'];
  delete data['riskInsightsPermission'];
  delete data['primaryUser'];
  delete data['name'];
  delete data['logo'];
  delete data['id'];
  delete data['customReminderIntervals'];
  delete data['finalReminderNotification'];
  ENABLED_FEATURES.forEach((entry) => (delete data[entry.name]));

  return JSON.stringify(data);
};

export const addSplashScreenContent = ({ data }, json) => {
  let splashScreenContent = json.data.filter((item) => item.contentType === 'SPLASH_SCREEN');
  if (splashScreenContent !== undefined && splashScreenContent.length > 0) {
    data.landingScreenContent = splashScreenContent[0].content;
  }
  data.clientId = data.id;
  return data;
};

const parseDaysToDuration = ( days ) => {
  const durationList = [];
  days?.forEach((day) => {
    durationList.push(generateDurationStringFromDays(day.interval))
  })
  return durationList;
}

const parseDurationToDays = ( durations ) => {
  const days = [];
  durations?.forEach((duration) => {
    days.push({
      interval : calculateDaysFromDuration(duration),
    })
  })
  return days;
}