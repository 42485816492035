
export function parseForAction() {
  let obj = {};
  obj.id = localStorage.getItem('clientId');
  return obj;
}

export function parseForAdminAction(clientId) {
  let obj = {};
  obj.id = clientId;
  return obj;
}

export function parseClientDefaultsDataForList(data) {

  data.map((element, index) => {
    element.id = index;
    return element;
  });

  return data; 
}

export function parseDatatoShowClientDefaults(data) {
  data.id = data['clientId'];
  data.clientDefaultList = data['clientDefaultList'].map((element) => {
    element.value = Number(element.value);
    return element;
  })
  
  delete data['clientId'];

  return data;
}

export function parseDatatoSaveClientDefaults(data) {

  const clientDefaultList = data?.clientDefaultList === null ? [] : data.clientDefaultList;
  
  const parsedRequest = {
    clientId : localStorage.getItem('clientId'),
    clientDefaultList : clientDefaultList?.map((element) => {
      element.value = element.value.toString();
      return element;
    }),
  }

  return JSON.stringify(parsedRequest);
}

export function parseDatatoSaveClientDefaultsForAdmin(data) {

  const clientDefaultList = data?.clientDefaultList === null ? [] : data.clientDefaultList;
  
  const parsedRequest = {
    clientId : data.id,
    clientDefaultList : clientDefaultList.map((element) => {
      element.value = element.value.toString();
      return element;
    }),
  }

  return JSON.stringify(parsedRequest);
}
