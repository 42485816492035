import { getCreationMessage } from "../../utils/util";
import {useNotify,useRefresh,useRedirect,SaveButton} from 'react-admin'
import CreateAndSendEmail from '../reports/CreateAndSendEmail';
import {ActionTitles, RESOURCES} from '../Constants'
import {baseButtonStyle,padding0,padding2} from '../Styles'
import {Button,Grid} from '@mui/material'
import ListItem from '@mui/material/ListItem'

//receives prop as buttontitle1 & buttontitle2 for both buttons respectively
const CustomToolbar = (props) => {  
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccessWithRedirect = ( data ) => {
    const message = getCreationMessage(props.resource, props.isEdit, data);
    notify(message, { type: 'info', autoHideDuration: 1000 });
    data.parsedRequestBody != null &&
    data.parsedRequestBody.scheduleRequest != null
      ? redirect("show", "/Schedules", data.reportRequestScheduleId)
      : (props.resource === RESOURCES.RISK_INSIGHTS || props.resource === RESOURCES.CLIENT_DEFAULTS || props.resource === RESOURCES.DEFAULT_CONTENT)
          ? redirect(props.basePath)
          : handleShowRedirect(props, data.id);
  };

  const handleShowRedirect = (props, id) => {
    if(props.enableSecondCreateReportPage)
      redirect(props.basePath + "/" + id + "/show?expandedReport=true");
    else
      redirect(props.basePath + "/" + id + "/show");
  }

  const onSuccessWithoutRedirect = ( data ) => {
    const message = getCreationMessage(props.resource, props.isEdit, data);
    notify(message, { type: 'info', autoHideDuration: 1000 });
    refresh();
  };

  const onFailure = (error) => {
    notify(error.message, { type: 'warning', autoHideDuration: 1000 });
  }

  return (
    <ListItem sx={{...padding0.top, ...padding2.bottom}}>
      <Grid container justifyContent="flex-end">
        <Grid container item xs={12} lg={10} justifyContent="flex-end">
          <Grid container item xs={12} md={3}>
            <Button
              variant="outlined"
              icon={<div />}
              size="medium"
              sx={{...baseButtonStyle("grey", "white", "#eaeaea", "#b2afaf", "#9B9999")}}
              onClick={() => redirect(props.basePath)}
            >
              {ActionTitles.CANCEL}
            </Button>
          </Grid>
          {!props.hideButton1 && !props.forReports && (
            <Grid container item xs={12} md={3}>
              <SaveButton
                type="button"
                icon={<div />}
                size="medium"
                mutationOptions={{ onSuccess: onSuccessWithoutRedirect, onError: onFailure }}
                label={props.buttontitle1}
                key={`button-1`}
                sx={{...baseButtonStyle()}}
              />
            </Grid>
          )}
          <Grid container item xs={12} md={3}>
            <SaveButton
              type="button"
              icon={<div />}
              size="medium"
              mutationOptions={{ onSuccess: onSuccessWithRedirect, onError: onFailure }}
              label={ props.buttontitle2}
              key={`button-2`}
              sx={{...baseButtonStyle()}}
            />
          </Grid>
          {!props.hideButton2 && props.forReports && (
            <Grid container item xs={12} md={3}>
              <CreateAndSendEmail {...props} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default CustomToolbar;
