import {ActionTitles, RESOURCES, RESOURCES_BASEPATH} from '../Constants'
import CustomToolbar from '../common/FormToolbar'
import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  maxLength,
  required,
  SimpleForm,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh
} from 'react-admin'
import ListActions from '../common/ListActions'
import keycloak from '../../keycloak'
import {useParams} from 'react-router-dom'
import {contentCenter,margin0,padding0,padding2,simpleForm,textColor,width} from '../Styles'
import {
  emailWithSpacesValidation,
  sameEmailDomainCheck,
  secondaryEmailValidation,
  secondaryEmailWithSpacesValidation, validateCurrentPassword, validatePasswordStrength
} from '../../utils/util'
import {isClientAdminUser} from '../DataProvider'
import {Grid} from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import {PasswordInput, TextInput} from 'ra-ui-materialui'
import Divider from '@mui/material/Divider'
import React from 'react'

export const SettingsEdit = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { id } = useParams();

  const onSuccess = ( data ) => {
    notify("Settings Updated", { type: 'info', autoHideDuration: 1000 });
    if  (data.passwordUpdated)  {
      keycloak.logout();
    }  else  {
      redirect(RESOURCES_BASEPATH.SETTINGS + "/" + id + "/show");
      refresh();
    }
  };

  return (
    <Edit
      actions={
        <ListActions
          title={ActionTitles.USER_UPDATE}
          showViewButton={true}
        />
      }
      renderButtonAndSearch={false}
      sx={contentCenter}
      mutationOptions={{
        onSuccess: onSuccess,
      }}
      mutationMode="pessimistic"
    >
      <SimpleForm
        toolbar={
          <CustomToolbar
            buttontitle1={ActionTitles.UPDATE_AND_CONTINUE}
            buttontitle2={ActionTitles.UPDATE}
            isEdit={true}
            basePath={RESOURCES_BASEPATH.SETTINGS}
            resource={RESOURCES.SETTINGS}
          />
        }
        mode="onBlur"
        reValidateMode="onBlur"
        sx={simpleForm}
      >
        <SettingsEditGrid />
      </SimpleForm>
    </Edit>
  );
};

export const SettingsEditGrid = (props) => {
  let record = useRecordContext();
  const isClientAdmin = isClientAdminUser();

  const validate = required("This is a mandatory field.");
  const validateMaxLength = [validate, maxLength(255)];
  const validateEmail = [validate, emailWithSpacesValidation, maxLength(254)];
  const validateEmailforClientAdmin = [...validateEmail, sameEmailDomainCheck];
  const validateSecondaryEmail = [secondaryEmailValidation, secondaryEmailWithSpacesValidation, maxLength(254)];

  return (
    <Grid container flexGrow={1}>
      <Grid xs={12}>
        <List sx={padding0.y}>
          <ListItem sx={padding2.y}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>
                First Name
                <span style={{ ...textColor.red, marginLeft: "3px" }}>*</span>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <TextInput
                  sx={{...width.fluid.w100, marginBottom: "-15px"}}
                  source="firstName"
                  label="First Name"
                  defaultValue={record["FIRST NAME"]}
                  variant="outlined"
                  size="small"
                  validate={validateMaxLength}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>
                Last Name
                <span style={{ ...textColor.red, marginLeft: "3px" }}>*</span>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <TextInput
                  source="lastName"
                  label="Last Name"
                  defaultValue={record["LAST NAME"]}
                  variant="outlined"
                  size="small"
                  validate={validateMaxLength}
                  sx={{...width.fluid.w100, marginBottom: "-15px"}}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          {props.showEmailEditField === true && (
            <>
              <ListItem sx={padding2.y}>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={4} sx={textColor.gray}>
                    Email
                    <span style={{ ...textColor.red, marginLeft: "3px" }}>*</span>
                  </Grid>
                  <Grid item xs={12} md={6} lg={5}>
                    <TextInput
                      source="email"
                      label="Email"
                      defaultValue={record["email"]}
                      variant="outlined"
                      size="small"
                      validate={isClientAdmin ? validateEmailforClientAdmin: validateEmail}
                      sx={{...width.fluid.w100, marginBottom: "-15px"}}
                    />
                  </Grid>
                </Grid>
              </ListItem>
              <Divider light />
            </>
          )}
          <ListItem sx={padding2.y}>
            <Grid container alignItems="center">
              <Grid xs={12} md={4} sx={textColor.gray}>Email Report Notification</Grid>
              <Grid xs={12} md={8}>
                <BooleanInput
                  sx={{marginBottom: "-15px"}}
                  label={false}
                  source="emailReportNotifications"
                  defaultValue={record["emailReportNotifications"]}
                />
              </Grid>
            </Grid>
          </ListItem>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.emailReportNotifications &&
              <>
                <Divider light />
                <ListItem sx={padding2.y}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} md={4} sx={textColor.gray}>Secondary Email</Grid>
                    <Grid item xs={12} md={6} lg={5}>
                      <TextInput
                        source="secondaryEmail"
                        label="Secondary Email"
                        variant="outlined"
                        size="small"
                        validate={validateSecondaryEmail}
                        {...rest}
                        sx={{...width.fluid.w100, marginBottom: "-15px"}}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              </>
            }
          </FormDataConsumer>
          {!record["multiFAConfigured"] && props.isUserResource === true
            ? <>
              <Divider light />
              <ListItem sx={padding2.y}>
                <Grid container alignItems="center">
                  <Grid xs={12} md={4} sx={textColor.gray}>Enforce 2FA</Grid>
                  <Grid xs={12} md={8}>
                    <BooleanInput
                      sx={{marginBottom: "-15px"}}
                      label={false}
                      source="enforceForMultiFAConfiguration"
                      defaultValue={record["enforceForMultiFAConfiguration"]}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </>
            : null}
          { isClientAdmin && props.isUserResource === true
            ? null
            :
            <>
              <Divider light />
              <ListItem sx={padding2.y}>
                <Grid container>
                  <Grid item xs={12} md={4} sx={{...textColor.gray, marginTop: { md: "15px"} }}>
                    {props.isUserResource === true //same component used in settings page, hence showing Admin Password on User's screen and Current Password on Settings page
                      ? "Admin Password"
                      : "Current Password"}
                  </Grid>
                  <Grid item xs={12} md={6} lg={5}>
                    <PasswordInput
                      source="currentPassword"
                      label={
                        props.isUserResource === true
                          ? "Admin Password"
                          : "Current Password"
                      }
                      variant="outlined"
                      size="small"
                      validate={validateCurrentPassword}
                      sx={{...width.fluid.w100, marginBottom: "-15px"}}
                    />
                    <p style={{ ...textColor.gray, ...margin0.default }}>
                      <small>Required when updating a user's password</small>
                    </p>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider light />
              <ListItem sx={padding2.y}>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={4} sx={textColor.gray}>New Password</Grid>
                  <Grid item xs={12} md={6} lg={5}>
                    <PasswordInput
                      source="newPassword"
                      label="Password"
                      variant="outlined"
                      size="small"
                      validate={validatePasswordStrength}
                      sx={{...width.fluid.w100, marginBottom: "-15px"}}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </>
          }
        </List>
        <Divider light />
      </Grid>
    </Grid>
  );
};