import React, {
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import ActionDelete from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  useDeleteWithConfirmController, useResourceContext,
} from 'ra-core';
import {Button, useDataProvider} from 'react-admin'
import {margin0, padding0, padding2, padding3, topActionButtonBaseStyle} from '../components/Styles'
import {ActionTitles} from '../components/Constants'
import CancelIcon from '@mui/icons-material/CancelOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import {CircularProgress} from '@mui/material'

const CustomDeleteWithConfirmButton = (props) => {
  const dataProvider = useDataProvider();
  const resource = useResourceContext();
  const {
    basePath,
    classes: classesOverride,
    className,
    icon = defaultIcon,
    label = 'ra.action.delete',
    mutationMode,
    onClick,
    record,
    recordName,
    redirect = 'list',
    onSuccess,
    onFailure,
    ...rest
  } = props;
  const {
    open,
    loading,
    handleDialogOpen,
    handleDialogClose,
  } = useDeleteWithConfirmController({
    record,
    redirect,
    basePath,
    mutationMode,
    onClick,
    onSuccess,
    onFailure,
    resource,
  });

  const handleDelete = () => {
    props.setDisableDelete(true);
    dataProvider.delete(
        resource,
        { id: record.id , previousData: record }
    ).then(()=>{onSuccess()})
    .catch(()=>{onFailure()});
}

  return (
      <Fragment>
          <Button
              onClick={handleDialogOpen}
              label={label}
              key="button"
              {...rest}
          >
              {icon}
          </Button>
          <Dialog open={open} onClose={handleDialogClose}>
            <DialogContent>
                <p>Are you sure you want to delete this {recordName}?</p>
                <p style={margin0.y}>This action cannot be undone.</p>
            </DialogContent>
            <DialogActions sx={{...padding0.top, ...padding2.bottom, ...padding3.x}}>
                <Button
                  onClick={handleDialogClose}
                  disabled={loading}
                  sx={{...topActionButtonBaseStyle("gray", "#fff", "#eaeaea", "#b2afaf", "#9B9999")}}
                  size="small"
                  variant="outlined"
                  label={ActionTitles.CANCEL}
                >
                  <CancelIcon />
                </Button>
                <Button
                  variant="contained"
                  onClick={handleDelete}
                  disabled={loading || props.disableDelete}
                  sx={{...topActionButtonBaseStyle()}}
                  size="small"
                  label={ActionTitles.DELETE}
                >
                  {
                    (loading || props.disableDelete) ?
                      <CircularProgress
                        size={14}
                        thickness={3}
                        color="inherit"
                      /> :
                      <DeleteOutlinedIcon />}
                </Button>
            </DialogActions>
        </Dialog>
      </Fragment>
  );
};

const defaultIcon = <ActionDelete />;

CustomDeleteWithConfirmButton.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.any,
  recordName: PropTypes.string,
  redirect: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.func,
  ]),
  resource: PropTypes.string,
  icon: PropTypes.element,
};

export default CustomDeleteWithConfirmButton;
