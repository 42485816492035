import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/date-picker.css";
import { useFormContext, useWatch } from 'react-hook-form';
import moment from "moment-timezone";
import momentDate from "../../utils/moment-date";
import {REPORT_TYPES,DEFAULT_TIME_RANGES_FOR_REPORT_TYPE} from '../Constants'
import { shouldDateBeStartOfMonth } from "../../utils/util";
import { DateInput } from "ra-ui-materialui";
import { validateEndDate, validateStartDate } from "../../utils/CreateReportUtils";
import {Grid} from '@mui/material'

const CustomDatePicker = (props) => {
  const form = useFormContext();
  const values = useWatch();
  const [startDate, setStartDate] = React.useState(
    initialStartDate(values.reportType)
  );
  const [endDate, setEndDate] = React.useState(new Date());
  const [firstPeriodEndDate, setFirstPeriodEndDate] = React.useState(
    new Date()
  ); //being used in case of report scheduling

  if (!values.startDate) {
    form.setValue("startDate", momentDate(startDate).format("YYYY-MM-DD"));
  }
  if (!values.endDate) {
    form.setValue("endDate", momentDate(endDate).format("YYYY-MM-DD"));
  }
  if (!values.firstPeriodEndDate) {
    form.setValue("firstPeriodEndDate", momentDate(firstPeriodEndDate).format("YYYY-MM-DD"));
  }

  const handleStartDateChange = (val) => {
    setStartDate(val);
    form.setValue("startDate", momentDate(val).format("YYYY-MM-DD"), { shouldValidate: true });
  };

  const handleEndDateChange = (val) => {
    setEndDate(val);
    form.setValue("endDate", momentDate(val).format("YYYY-MM-DD"), { shouldValidate: true });
  };

  const handlePeriodEndDateChange = (val) => {
    setFirstPeriodEndDate(val);
    form.setValue("firstPeriodEndDate", val);
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const isFirstOfMonth = (date) => {
    const currentDate = date.getDate();
    return currentDate === 1;
  };

  return (
    <React.Fragment>
      {props.isStartDate && (                       //This hidden input's are used to invalidate form
        <Grid display="none">
          <DateInput
            source="startDate"
            defaultValue={startDate}
            validate={validateStartDate}
          />
        </Grid>
      )}
      {props.isEndDate && (
        <Grid display="none">
          <DateInput
            source="endDate"
            defaultValue={endDate}
            validate={validateEndDate}
          />
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12} md={9}>
          <DatePicker
            popperPlacement="top"
            wrapperClassName="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-sizeSmall"
            onChangeRaw={handleDateChangeRaw}
            onFocus={(ev) => {
              ev.preventDefault()
              ev.target.setAttribute('inputmode', 'none')
            }}
            popperModifiers={{
              flip: {
                behavior: ["top"], // don't allow it to flip to be above
              },
              preventOverflow: {
                enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                enabled: false, // turn off since needs preventOverflow to be enabled
              },
            }}
            selected={
              props.isStartDate === true
                ? startDate
                : (props.isEndDateOfFirstReport
                  ? firstPeriodEndDate
                  : endDate)
            }
            onChange={
              props.isStartDate === true
                ? handleStartDateChange
                : (props.isEndDateOfFirstReport
                  ? handlePeriodEndDateChange
                  : handleEndDateChange)
            }
            peekNextMonth
            maxDate={!props.isEndDateOfFirstReport ? new Date() : null}
            minDate={
              props.isEndDateOfFirstReport ? props.reportScheduleMinDate : null
            }
            showMonthDropdown
            showYearDropdown
            dateFormat="dd/MM/yyyy"
            dropdownMode="select"
            filterDate={
              shouldDateBeStartOfMonth(values.reportType) &&
              props.isStartDate === true
                ? isFirstOfMonth
                : null
            }

          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CustomDatePicker;

export const initialStartDate = (reportType) => {
  if (!reportType) return;

  // removing this default start of 3 months , as when component was rendered for first time and reportType was not selected
  //it was defaulted to 3 months and validation error used to come even when SOFA report had 6 months selected
  let startDate = moment().subtract(
    DEFAULT_TIME_RANGES_FOR_REPORT_TYPE[reportType],
    "months"
  );

  const date = new Date(startDate);
  if (
    (reportType === REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS || reportType === REPORT_TYPES.TRANSACTION_ANALYSIS) &&
    date.getDate() !== 1
  ) {
    date.setDate(1);
    return date;
  }
  return startDate.toDate(); //react datepicker expects initial date in javascript date format only
};

export const initialEndDate = () => {
  return moment().toDate();
};
